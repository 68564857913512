import EmblaCarousel from 'embla-carousel'
import { setCSSVar } from '../../src/scripts/utils';

class FeatureSlider extends HTMLElement {
  constructor () {
    super()

    this.state = {}
    this.imageSlider = this.querySelector('.image-slider');
    this.contentSlider = this.querySelector('.content-slider');
    this.contentSlides = this.contentSlider.querySelectorAll('.slide-content');
    this.controls = this.querySelector('.slider-controls');
    this.counters = this.querySelectorAll('.slider-counter--current');

    this.handleSlideFocusIn = this.handleSlideFocusIn.bind(this)
    this.resetScrollPosition = this.resetScrollPosition.bind(this)
    this.setup = this.setup.bind(this)
    this.teardown = this.teardown.bind(this)
    this.prev = this.controls.querySelector('.prev');
    this.next = this.controls.querySelector('.next');

    this.setup()

    this.parentElement.parentElement.addEventListener('shopify:section:unload', () => {
      this.teardown()
    })
    this.parentElement.parentElement.addEventListener('shopify:section:load', () => {
      this.setup()
    })
    this.parentElement.parentElement.addEventListener('shopify:block:select', (event) => {
      console.log({ event, index: [...event.target.parentElement.children].indexOf(event.target) })
      this.embla.scrollTo([...event.target.parentElement.children].indexOf(event.target))
    })
  }

  setup () {
    if (this.imageSlider.querySelectorAll('.slide-image').length <= 1) {
      return
    }

    const options = {
        loop: false,
        align: 'end',
        breakpoints: {
            '(min-width: 990px)': { direction: 'rtl', align: 'start' }
        }
    };
    
    this.equalizeHeights();

    this.state.activeContent = this.contentSlider.querySelector('.active');
    
    this.embla = EmblaCarousel(this.imageSlider, options)
   
    this.embla.on('select', this.updateSliders.bind(this));
    this.prev.addEventListener('click', this.embla.scrollPrev, false);
    this.next.addEventListener('click', this.embla.scrollNext, false);

    // Lock horizontal scroll for parent element
    this.parentElement.addEventListener('scroll', this.resetScrollPosition)

    // Jump to focused slide when moving with keyboard
   // this.addEventListener('focusin', this.handleSlideFocusIn)

    if (this.state.selectedScrollSnap) {
      this.embla.scrollTo(this.state.selectedScrollSnap)
    }
  }

  teardown () {
    if (this.embla) {
      this.state.selectedScrollSnap = this.embla.selectedScrollSnap
      this.embla.destroy()
      this.parentElement.removeEventListener('scroll', this.handleSlideFocusIn)
      this.removeEventListener('focusin', this.handleSlideFocusIn)
    }
  }

  resetScrollPosition () {
    this.parentElement.scrollTo(0, 0)
  }

  handleSlideFocusIn (event) {
    const slide = event.target.closest('.slide-image')
    const slideIndex = parseInt(slide.getAttribute('data-slide-index'), 10) - 1

    this.embla.scrollTo(slideIndex, true)
  }
  
  equalizeHeights() {
    const slides =  Array.from(this.contentSlides);
    const tallest = Math.max(
        ...slides.map(item => item.getBoundingClientRect().height)
    );
    
    setCSSVar('--slider-height', tallest+'px', this.contentSlider); 
    
  }

  updateSliders () {
        
    const newIndex = this.embla.selectedScrollSnap();
    const newCtnSlide = this.contentSlides.item(newIndex);

    this.state.activeContent.classList.remove('active','z-2');
    newCtnSlide.classList.add('active', 'z-2');

    this.counters.forEach( ctr => ctr.innerText = newIndex + 1);
    this.state.activeContent = newCtnSlide;

  }

}

customElements.define('feature-slider', FeatureSlider)
